import {
  Tolgee,
  DevTools,
  VueTolgee,
  FormatSimple,
  TolgeeProvider,
} from "@tolgee/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatSimple())
    .init({
      language: "lv",
      defaultNs: "pwa",
      ns: ["pwa"],
      // for development
      apiUrl: runtimeConfig.public.tolgeeApiUrl,
      apiKey: runtimeConfig.public.tolgeeApiKey,
      // for production
      staticData: {
        "en:pwa": () => import("../i18n/pwa/en.json"),
        "lv:pwa": () => import("../i18n/pwa/lv.json"),
      },
    });

  nuxtApp.vueApp.use(VueTolgee, { tolgee });

  return {
    provide: {
      TolgeeProvider,
      tolgee,
    },
  };
});
