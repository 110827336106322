import { STORAGE_KEY } from "axios-jwt/dist/src/StorageKey";
import jwtDecode from "jwt-decode";
import * as Sentry from "@sentry/browser";

interface TOKENS {
  accessToken: string;
  refreshToken: string;
}

const getTokens = (): TOKENS | null => {
  const tokenStorage = localStorage.getItem(STORAGE_KEY);
  if (!tokenStorage) {
    return null;
  }

  return JSON.parse(tokenStorage as string);
};

const isLoggedIn = (role: string): boolean => {
  const tokens = getTokens();
  if (!tokens) {
    Sentry.setUser({ id: undefined });
    return false;
  }

  const data = jwtDecode(tokens.accessToken) as {
    sub: string;
    role: string;
  };

  Sentry.setUser({ id: data.sub });

  return data.role === role;
};

export default defineNuxtRouteMiddleware((to) => {
  if (to.path === "/" && !isLoggedIn("VOLUNTEER")) {
    return navigateTo("/auth");
  }

  if (to.path.startsWith("/training") && !isLoggedIn("APPLICANT")) {
    return navigateTo("/auth");
  }

  if (to.path.startsWith("/profile") && !isLoggedIn("VOLUNTEER")) {
    return navigateTo("/auth");
  }

  if (to.path.startsWith("/tasks") && !isLoggedIn("VOLUNTEER")) {
    return navigateTo("/auth");
  }
});
