import moment from "moment";
import dayjs from "dayjs";

export const defaultDateTimeFormat = "DD.MM.YYYY HH:mm";
export const defaultDateFormat = "DD.MM.YYYY";

export const defaultValueFormat = "YYYY-MM-DDTHH:mm:ss";
export const defaultTimeFormat = "HH:mm";
export const defaultDateValueFormat = "YYYY-MM-DD";

export const convertDateRangeToFullDays = (dates: any): any[] | undefined => {
  if (dates && dates.length === 2) {
    const startDate = dayjs(dates[0]).startOf("day");
    const endDate = dayjs(dates[1]).endOf("day");
    return [startDate, endDate] as any[];
  }
};

export default defineNuxtPlugin(() => {
  return {
    provide: {
      formatDateTime: (date: string) => {
        return moment(date).format(defaultDateTimeFormat);
      },
      formatDate: (date: string) => {
        return moment(date).format(defaultDateFormat);
      },
      formatTime: (date: string) => {
        return moment(date).format(defaultTimeFormat);
      },
      formatDateRange: (dateRange: string[]) => {
        return [
          moment(dateRange[0]).format(defaultDateFormat),
          moment(dateRange[1]).format(defaultDateFormat),
        ].join(" - ");
      },
    },
  };
});
