import { default as indexaVPDnhf1OvMeta } from "/app/pages/auth/index.vue?macro=true";
import { default as indexlXWKW23Hj9Meta } from "/app/pages/auth/login/index.vue?macro=true";
import { default as localq9z56yy42EMeta } from "/app/pages/auth/login/local.vue?macro=true";
import { default as _91id_93hgDYFWbRSRMeta } from "/app/pages/auth/password-reset/[id].vue?macro=true";
import { default as indexWPZ2P1xJPvMeta } from "/app/pages/auth/password-reset/index.vue?macro=true";
import { default as indexV6gwatfXEpMeta } from "/app/pages/auth/register/email/index.vue?macro=true";
import { default as _91id_93sSsx6c3pvxMeta } from "/app/pages/auth/register/email/verify/[id].vue?macro=true";
import { default as indexKZURqMja58Meta } from "/app/pages/auth/register/email/verify/index.vue?macro=true";
import { default as indexFfkaDmrbi7Meta } from "/app/pages/auth/register/index.vue?macro=true";
import { default as indexqtwOY1KkcIMeta } from "/app/pages/auth/register/phone/index.vue?macro=true";
import { default as verifyEqy7ITDZSnMeta } from "/app/pages/auth/register/phone/verify.vue?macro=true";
import { default as profileZofZPeWVJpMeta } from "/app/pages/auth/register/profile.vue?macro=true";
import { default as starteNo07NLOImMeta } from "/app/pages/auth/register/start.vue?macro=true";
import { default as successfVwuB9vkY7Meta } from "/app/pages/auth/register/success.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93UWjZcLYjk2Meta } from "/app/pages/profile/change-email/[id].vue?macro=true";
import { default as indexH66QskxwuBMeta } from "/app/pages/profile/change-email/index.vue?macro=true";
import { default as indexoFj1cvseE5Meta } from "/app/pages/profile/change-phone/index.vue?macro=true";
import { default as verifyc7mLKtb7vuMeta } from "/app/pages/profile/change-phone/verify.vue?macro=true";
import { default as indexNdCj1xjJiKMeta } from "/app/pages/profile/edit/index.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as indexcMxd1VRwRiMeta } from "/app/pages/profile/worktypes/index.vue?macro=true";
import { default as _91id_93MgPppxubXVMeta } from "/app/pages/tasks/[id].vue?macro=true";
import { default as indexG8Y0JnMIO5Meta } from "/app/pages/tasks/index.vue?macro=true";
import { default as indexoBbhoTzapOMeta } from "/app/pages/tasks/my-tasks/index.vue?macro=true";
import { default as canceled7gAy1oPGjlMeta } from "/app/pages/training/canceled.vue?macro=true";
import { default as index1UnJMNXLd4Meta } from "/app/pages/training/certificate/index.vue?macro=true";
import { default as success6dBSSaOTlmMeta } from "/app/pages/training/certificate/success.vue?macro=true";
import { default as index4ouYh8FpPxMeta } from "/app/pages/training/index.vue?macro=true";
import { default as index78MoJh9ziCMeta } from "/app/pages/training/register/index.vue?macro=true";
import { default as cancelhVtUPw99jgMeta } from "/app/pages/training/register/payment/cancel.vue?macro=true";
import { default as failureXuNO7HWUbJMeta } from "/app/pages/training/register/payment/failure.vue?macro=true";
import { default as indexxIfMosNrKTMeta } from "/app/pages/training/register/payment/index.vue?macro=true";
import { default as successTe7NOr3QLnMeta } from "/app/pages/training/register/payment/success.vue?macro=true";
export default [
  {
    name: indexaVPDnhf1OvMeta?.name ?? "auth",
    path: indexaVPDnhf1OvMeta?.path ?? "/auth",
    meta: indexaVPDnhf1OvMeta || {},
    alias: indexaVPDnhf1OvMeta?.alias || [],
    redirect: indexaVPDnhf1OvMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexlXWKW23Hj9Meta?.name ?? "auth-login",
    path: indexlXWKW23Hj9Meta?.path ?? "/auth/login",
    meta: indexlXWKW23Hj9Meta || {},
    alias: indexlXWKW23Hj9Meta?.alias || [],
    redirect: indexlXWKW23Hj9Meta?.redirect || undefined,
    component: () => import("/app/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: localq9z56yy42EMeta?.name ?? "auth-login-local",
    path: localq9z56yy42EMeta?.path ?? "/auth/login/local",
    meta: localq9z56yy42EMeta || {},
    alias: localq9z56yy42EMeta?.alias || [],
    redirect: localq9z56yy42EMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/login/local.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hgDYFWbRSRMeta?.name ?? "auth-password-reset-id",
    path: _91id_93hgDYFWbRSRMeta?.path ?? "/auth/password-reset/:id()",
    meta: _91id_93hgDYFWbRSRMeta || {},
    alias: _91id_93hgDYFWbRSRMeta?.alias || [],
    redirect: _91id_93hgDYFWbRSRMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/password-reset/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWPZ2P1xJPvMeta?.name ?? "auth-password-reset",
    path: indexWPZ2P1xJPvMeta?.path ?? "/auth/password-reset",
    meta: indexWPZ2P1xJPvMeta || {},
    alias: indexWPZ2P1xJPvMeta?.alias || [],
    redirect: indexWPZ2P1xJPvMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexV6gwatfXEpMeta?.name ?? "auth-register-email",
    path: indexV6gwatfXEpMeta?.path ?? "/auth/register/email",
    meta: indexV6gwatfXEpMeta || {},
    alias: indexV6gwatfXEpMeta?.alias || [],
    redirect: indexV6gwatfXEpMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/email/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sSsx6c3pvxMeta?.name ?? "auth-register-email-verify-id",
    path: _91id_93sSsx6c3pvxMeta?.path ?? "/auth/register/email/verify/:id()",
    meta: _91id_93sSsx6c3pvxMeta || {},
    alias: _91id_93sSsx6c3pvxMeta?.alias || [],
    redirect: _91id_93sSsx6c3pvxMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/email/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKZURqMja58Meta?.name ?? "auth-register-email-verify",
    path: indexKZURqMja58Meta?.path ?? "/auth/register/email/verify",
    meta: indexKZURqMja58Meta || {},
    alias: indexKZURqMja58Meta?.alias || [],
    redirect: indexKZURqMja58Meta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/email/verify/index.vue").then(m => m.default || m)
  },
  {
    name: indexFfkaDmrbi7Meta?.name ?? "auth-register",
    path: indexFfkaDmrbi7Meta?.path ?? "/auth/register",
    meta: indexFfkaDmrbi7Meta || {},
    alias: indexFfkaDmrbi7Meta?.alias || [],
    redirect: indexFfkaDmrbi7Meta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexqtwOY1KkcIMeta?.name ?? "auth-register-phone",
    path: indexqtwOY1KkcIMeta?.path ?? "/auth/register/phone",
    meta: indexqtwOY1KkcIMeta || {},
    alias: indexqtwOY1KkcIMeta?.alias || [],
    redirect: indexqtwOY1KkcIMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/phone/index.vue").then(m => m.default || m)
  },
  {
    name: verifyEqy7ITDZSnMeta?.name ?? "auth-register-phone-verify",
    path: verifyEqy7ITDZSnMeta?.path ?? "/auth/register/phone/verify",
    meta: verifyEqy7ITDZSnMeta || {},
    alias: verifyEqy7ITDZSnMeta?.alias || [],
    redirect: verifyEqy7ITDZSnMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/phone/verify.vue").then(m => m.default || m)
  },
  {
    name: profileZofZPeWVJpMeta?.name ?? "auth-register-profile",
    path: profileZofZPeWVJpMeta?.path ?? "/auth/register/profile",
    meta: profileZofZPeWVJpMeta || {},
    alias: profileZofZPeWVJpMeta?.alias || [],
    redirect: profileZofZPeWVJpMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/profile.vue").then(m => m.default || m)
  },
  {
    name: starteNo07NLOImMeta?.name ?? "auth-register-start",
    path: starteNo07NLOImMeta?.path ?? "/auth/register/start",
    meta: starteNo07NLOImMeta || {},
    alias: starteNo07NLOImMeta?.alias || [],
    redirect: starteNo07NLOImMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/start.vue").then(m => m.default || m)
  },
  {
    name: successfVwuB9vkY7Meta?.name ?? "auth-register-success",
    path: successfVwuB9vkY7Meta?.path ?? "/auth/register/success",
    meta: successfVwuB9vkY7Meta || {},
    alias: successfVwuB9vkY7Meta?.alias || [],
    redirect: successfVwuB9vkY7Meta?.redirect || undefined,
    component: () => import("/app/pages/auth/register/success.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UWjZcLYjk2Meta?.name ?? "profile-change-email-id",
    path: _91id_93UWjZcLYjk2Meta?.path ?? "/profile/change-email/:id()",
    meta: _91id_93UWjZcLYjk2Meta || {},
    alias: _91id_93UWjZcLYjk2Meta?.alias || [],
    redirect: _91id_93UWjZcLYjk2Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/change-email/[id].vue").then(m => m.default || m)
  },
  {
    name: indexH66QskxwuBMeta?.name ?? "profile-change-email",
    path: indexH66QskxwuBMeta?.path ?? "/profile/change-email",
    meta: indexH66QskxwuBMeta || {},
    alias: indexH66QskxwuBMeta?.alias || [],
    redirect: indexH66QskxwuBMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/change-email/index.vue").then(m => m.default || m)
  },
  {
    name: indexoFj1cvseE5Meta?.name ?? "profile-change-phone",
    path: indexoFj1cvseE5Meta?.path ?? "/profile/change-phone",
    meta: indexoFj1cvseE5Meta || {},
    alias: indexoFj1cvseE5Meta?.alias || [],
    redirect: indexoFj1cvseE5Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/change-phone/index.vue").then(m => m.default || m)
  },
  {
    name: verifyc7mLKtb7vuMeta?.name ?? "profile-change-phone-verify",
    path: verifyc7mLKtb7vuMeta?.path ?? "/profile/change-phone/verify",
    meta: verifyc7mLKtb7vuMeta || {},
    alias: verifyc7mLKtb7vuMeta?.alias || [],
    redirect: verifyc7mLKtb7vuMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/change-phone/verify.vue").then(m => m.default || m)
  },
  {
    name: indexNdCj1xjJiKMeta?.name ?? "profile-edit",
    path: indexNdCj1xjJiKMeta?.path ?? "/profile/edit",
    meta: indexNdCj1xjJiKMeta || {},
    alias: indexNdCj1xjJiKMeta?.alias || [],
    redirect: indexNdCj1xjJiKMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile",
    path: indexaeouN7Kzh7Meta?.path ?? "/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexcMxd1VRwRiMeta?.name ?? "profile-worktypes",
    path: indexcMxd1VRwRiMeta?.path ?? "/profile/worktypes",
    meta: indexcMxd1VRwRiMeta || {},
    alias: indexcMxd1VRwRiMeta?.alias || [],
    redirect: indexcMxd1VRwRiMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/worktypes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MgPppxubXVMeta?.name ?? "tasks-id",
    path: _91id_93MgPppxubXVMeta?.path ?? "/tasks/:id()",
    meta: _91id_93MgPppxubXVMeta || {},
    alias: _91id_93MgPppxubXVMeta?.alias || [],
    redirect: _91id_93MgPppxubXVMeta?.redirect || undefined,
    component: () => import("/app/pages/tasks/[id].vue").then(m => m.default || m)
  },
  {
    name: indexG8Y0JnMIO5Meta?.name ?? "tasks",
    path: indexG8Y0JnMIO5Meta?.path ?? "/tasks",
    meta: indexG8Y0JnMIO5Meta || {},
    alias: indexG8Y0JnMIO5Meta?.alias || [],
    redirect: indexG8Y0JnMIO5Meta?.redirect || undefined,
    component: () => import("/app/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: indexoBbhoTzapOMeta?.name ?? "tasks-my-tasks",
    path: indexoBbhoTzapOMeta?.path ?? "/tasks/my-tasks",
    meta: indexoBbhoTzapOMeta || {},
    alias: indexoBbhoTzapOMeta?.alias || [],
    redirect: indexoBbhoTzapOMeta?.redirect || undefined,
    component: () => import("/app/pages/tasks/my-tasks/index.vue").then(m => m.default || m)
  },
  {
    name: canceled7gAy1oPGjlMeta?.name ?? "training-canceled",
    path: canceled7gAy1oPGjlMeta?.path ?? "/training/canceled",
    meta: canceled7gAy1oPGjlMeta || {},
    alias: canceled7gAy1oPGjlMeta?.alias || [],
    redirect: canceled7gAy1oPGjlMeta?.redirect || undefined,
    component: () => import("/app/pages/training/canceled.vue").then(m => m.default || m)
  },
  {
    name: index1UnJMNXLd4Meta?.name ?? "training-certificate",
    path: index1UnJMNXLd4Meta?.path ?? "/training/certificate",
    meta: index1UnJMNXLd4Meta || {},
    alias: index1UnJMNXLd4Meta?.alias || [],
    redirect: index1UnJMNXLd4Meta?.redirect || undefined,
    component: () => import("/app/pages/training/certificate/index.vue").then(m => m.default || m)
  },
  {
    name: success6dBSSaOTlmMeta?.name ?? "training-certificate-success",
    path: success6dBSSaOTlmMeta?.path ?? "/training/certificate/success",
    meta: success6dBSSaOTlmMeta || {},
    alias: success6dBSSaOTlmMeta?.alias || [],
    redirect: success6dBSSaOTlmMeta?.redirect || undefined,
    component: () => import("/app/pages/training/certificate/success.vue").then(m => m.default || m)
  },
  {
    name: index4ouYh8FpPxMeta?.name ?? "training",
    path: index4ouYh8FpPxMeta?.path ?? "/training",
    meta: index4ouYh8FpPxMeta || {},
    alias: index4ouYh8FpPxMeta?.alias || [],
    redirect: index4ouYh8FpPxMeta?.redirect || undefined,
    component: () => import("/app/pages/training/index.vue").then(m => m.default || m)
  },
  {
    name: index78MoJh9ziCMeta?.name ?? "training-register",
    path: index78MoJh9ziCMeta?.path ?? "/training/register",
    meta: index78MoJh9ziCMeta || {},
    alias: index78MoJh9ziCMeta?.alias || [],
    redirect: index78MoJh9ziCMeta?.redirect || undefined,
    component: () => import("/app/pages/training/register/index.vue").then(m => m.default || m)
  },
  {
    name: cancelhVtUPw99jgMeta?.name ?? "training-register-payment-cancel",
    path: cancelhVtUPw99jgMeta?.path ?? "/training/register/payment/cancel",
    meta: cancelhVtUPw99jgMeta || {},
    alias: cancelhVtUPw99jgMeta?.alias || [],
    redirect: cancelhVtUPw99jgMeta?.redirect || undefined,
    component: () => import("/app/pages/training/register/payment/cancel.vue").then(m => m.default || m)
  },
  {
    name: failureXuNO7HWUbJMeta?.name ?? "training-register-payment-failure",
    path: failureXuNO7HWUbJMeta?.path ?? "/training/register/payment/failure",
    meta: failureXuNO7HWUbJMeta || {},
    alias: failureXuNO7HWUbJMeta?.alias || [],
    redirect: failureXuNO7HWUbJMeta?.redirect || undefined,
    component: () => import("/app/pages/training/register/payment/failure.vue").then(m => m.default || m)
  },
  {
    name: indexxIfMosNrKTMeta?.name ?? "training-register-payment",
    path: indexxIfMosNrKTMeta?.path ?? "/training/register/payment",
    meta: indexxIfMosNrKTMeta || {},
    alias: indexxIfMosNrKTMeta?.alias || [],
    redirect: indexxIfMosNrKTMeta?.redirect || undefined,
    component: () => import("/app/pages/training/register/payment/index.vue").then(m => m.default || m)
  },
  {
    name: successTe7NOr3QLnMeta?.name ?? "training-register-payment-success",
    path: successTe7NOr3QLnMeta?.path ?? "/training/register/payment/success",
    meta: successTe7NOr3QLnMeta || {},
    alias: successTe7NOr3QLnMeta?.alias || [],
    redirect: successTe7NOr3QLnMeta?.redirect || undefined,
    component: () => import("/app/pages/training/register/payment/success.vue").then(m => m.default || m)
  }
]