import { defineNuxtPlugin } from "#app";
import * as Sentry from "@sentry/browser";

export default defineNuxtPlugin((nuxtApp) => {
  const environment = nuxtApp.$config.public.environment;
  if (
    environment === "prod" ||
    environment === "uat" ||
    environment === "sit"
  ) {
    Sentry.init({
      dsn: "https://a8da6ed048ba49f78a2415ba2a084743@o4505317226840064.ingest.sentry.io/4505317237456896",
      environment,
      sampleRate: 1,
    });
  }
});
